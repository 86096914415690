import { useEffect, useState } from 'react';
import { Banner } from 'sections/Banner';
import { Countdown } from 'sections/Countdown';
import { Collection } from 'sections/Collection';
import { Roadmap } from 'sections/Roadmap';
import { Team } from 'sections/Team';
import { Faq } from 'sections/Faq';
import { ScrollUpButton } from 'components/ScrollUpButton';
// import { Partners } from 'sections/Partners';

const Main: React.FC = () => {
  useEffect(() => {
    document.title = 'HOME | Pizzaverse NFT Collection';
  }, []);

  const [visible, setVisible] = useState(false);

  const handleIsScrollUpVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(true);
    } else if (scrolled <= 0) {
      setVisible(false);
    }
  };

  window.addEventListener('scroll', handleIsScrollUpVisible);

  return (
    <div className="bg-background">
      <Banner />
      {visible && <ScrollUpButton />}
      {/* TODO: Add the countdown back */}

      <Countdown />
      <Collection />
      <Roadmap />
      <Team />
      {/* <Partners /> */}
      <Faq />
    </div>
  );
};

export default Main;
