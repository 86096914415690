import { useEffect, useState } from 'react';
import PizzaLove from 'assets/pizzaLove.jpeg';
import AutoCarousel from 'components/Carousel/Carousel';
import 'react-multi-carousel/lib/styles.css';
import { ADDRESS, ABI } from 'ethereum';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import getWhitelistedAddresses from 'utils/getWhitelistedAddresses';

interface Props {
  currentAccount?: string;
  currentChainId?: string;
  isMetamaskMissing?: boolean;
}

const Mint = (props: Props) => {
  const { currentAccount, currentChainId, isMetamaskMissing } = props;
  useEffect(() => {
    document.title = 'MINT | Pizzaverse NFT Collection';
  }, []);

  const [nftAmount, setNftAmount] = useState<number>(0);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const stringNumber = e.target.value;
    const inputNumber = parseInt(stringNumber, 10);
    setNftAmount(inputNumber);
  };

  const handleClickMint = async () => {
    try {
      const response: string[] = await getWhitelistedAddresses(
        currentAccount ? currentAccount : ''
      );
      const isAddressWhitelisted = response.length;
      const web3 = new Web3(window.ethereum);

      const pizzaverseContract = new web3.eth.Contract(ABI as any, ADDRESS); // TODO: Change any to "as AbiItem[]"

      const price = await pizzaverseContract.methods.mintPricePublic().call();

      const totalPrice = price * nftAmount;
      // TODO: During whitelist sale, add the isAddressWhitelisted condition
      pizzaverseContract.methods
        .publicMint(nftAmount)
        .send({ from: currentAccount, value: totalPrice })
        .on('transactionHash', function (hash: string) {
          console.log('MINTING :) - The transaction hash is:', hash);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-background">
      {isMetamaskMissing && (
        <div className="mb-1 bg-red-500">
          <p className="py-2 text-xl font-semibold text-center text-white font-varela">
            In order to Mint, please install Metamask
          </p>
        </div>
      )}

      {currentAccount &&
        currentChainId !== '1' &&
        currentChainId !== '0x1' && ( //TODO: Change to Mainnet
          <div className="bg-red-500">
            <p className="py-2 text-xl font-semibold text-center text-white font-varela">
              You are not on Ethereum. Connect your Metamask to Ethereum.
            </p>
          </div>
        )}
      <div className="flex items-center justify-center px-2 pt-2 bg-background lg:px-6 lg:pt-4">
        <p className="text-5xl uppercase md:text-7xl font-lovelo text-title">
          MINT
        </p>
      </div>
      <div className="flex items-center justify-between px-2 pt-5 lg:px-6 lg:pt-4">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      <div className="flex justify-center px-2 pt-4 lg:px-6 lg:pt-10 ">
        <div className="">
          <div className="flex items-center justify-center ">
            <input
              className="w-full px-4 py-3 text-4xl font-normal text-center bg-white rounded-full cursor-pointer text-text font-varela focus:border-transparent"
              placeholder="min 1 - max 3"
              type="number"
              onChange={(e) => handleInput(e)}
              disabled // TODO: enable during mint
            />
          </div>
          <div className="flex items-center justify-center pt-5">
            <button
              className="px-4 py-3 text-4xl font-semibold text-white uppercase rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title disabled:bg-gray-300"
              onClick={handleClickMint}
              disabled // TODO: enable during mint
            >
              MINT
            </button>
          </div>
          <div className="flex items-center justify-center pt-3">
            <p className="text-title text-s font-varela">Coming soon :)</p>
          </div>
          {!currentAccount && (
            <div className="flex items-center justify-center pt-3">
              <p className="text-red-600 text-s font-varela">
                Remember to connect your Metamask to Ethereum Mainnet
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="pt-7">
        <AutoCarousel
          numberOfItemsDesktop={5}
          firstImages
          secondImages={false}
        />
      </div>
    </div>
  );
};

export default Mint;
