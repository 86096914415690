import { Main } from 'pages/Main';
import { Story } from 'pages/Story';
import { Terms } from 'pages/Terms';
import { Mint } from 'containers/Mint';
import { Routes, Route } from 'react-router-dom';
import { Navbar } from 'controls/Navbar';
import { Footer } from 'controls/Footer';
import { Socials } from 'pages/Socials';
import { ErrorPage } from 'pages/ErrorPage';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const Page = () => {
  const navigate = useNavigate();

  const [currentAccount, setCurrentAccount] = useState<string | undefined>(
    undefined
  );
  const [currentChainId, setCurrentChainId] = useState<string | undefined>(
    undefined
  );
  const [isMetamaskMissing, setIsMetamaskMissing] = useState<boolean>(true);

  const handleConnetMetamaskAccount = async () => {
    if (!window.ethereum) {
      alert('Install Metamask on your Browser :) (Brave, Firefox, Chrome) ');
      return;
    }

    const chainId = window.ethereum.networkVersion;
    setCurrentChainId(chainId);
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log('Connect your Metamask to Ethereum :)');
    }
  };

  const handleLoadChain = (chainId: string) => {
    setCurrentChainId(chainId);
  };

  const handleChangeAccount = (account: string) => {
    setCurrentAccount(account);
  };

  useEffect(() => {
    if (!window.ethereum) {
      setIsMetamaskMissing(true);
    } else {
      setIsMetamaskMissing(false);
    }
  }, []);

  const handleClickLiveSales = () => {
    navigate('/mint');
  };

  return (
    <div>
      <Navbar
        currentAccount={currentAccount}
        onClickConnect={handleConnetMetamaskAccount}
        onLoadChain={(chainId) => handleLoadChain(chainId)}
        onChangeAccount={(account) => handleChangeAccount(account)}
      />
      {/* TODO: Uncomment when sales are live */}
      {/* TODO: change whitelist sales to sales during public sale */}

      {/* <div
        className="bg-green-500 cursor-pointer"
        onClick={handleClickLiveSales}
      >
        <p className="py-2 text-xl font-semibold text-center text-white font-varela">
          WHITELIST SALES ARE LIVE - Go to https://www.ownpizzaverse.com/mint
        </p>
      </div> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          path="/mint"
          element={
            <Mint
              isMetamaskMissing={isMetamaskMissing}
              currentAccount={currentAccount}
              currentChainId={currentChainId}
            />
          }
        />
        <Route path="/story" element={<Story />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/discord-is-private" element={<Socials />} />
        <Route path="/*" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Page;
