import { Page } from './containers/Page';

import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'utils/scrollToTop';

const App: React.FC = () => {
  const pizzaverseStringToConsole = `
  ██████╗ ██╗███████╗███████╗ █████╗ ██╗   ██╗███████╗██████╗ ███████╗███████╗
  ██╔══██╗██║╚══███╔╝╚══███╔╝██╔══██╗██║   ██║██╔════╝██╔══██╗██╔════╝██╔════╝
  ██████╔╝██║  ███╔╝   ███╔╝ ███████║██║   ██║█████╗  ██████╔╝███████╗█████╗  
  ██╔═══╝ ██║ ███╔╝   ███╔╝  ██╔══██║╚██╗ ██╔╝██╔══╝  ██╔══██╗╚════██║██╔══╝  
  ██║     ██║███████╗███████╗██║  ██║ ╚████╔╝ ███████╗██║  ██║███████║███████╗
  ╚═╝     ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝
  `;

  console.log(pizzaverseStringToConsole);

  return (
    <Router>
      <ScrollToTop />
      <Page />
    </Router>
  );
};

export default App;
