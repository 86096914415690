
const allLinks = {
  pizzaverseTwitter: "https://twitter.com/PizzaverseNFT",
  pizzaverseDiscord: "",
  whitepaper: "",
  daoExplanation: "https://en.wikipedia.org/wiki/Decentralized_autonomous_organization",
  michelinRestaurant: "https://en.wikipedia.org/wiki/Michelin_Guide",
  cloudKitchesExaplation: "https://en.wikipedia.org/wiki/Ghost_kitchen",
  marzanoTwitter: "",
  marzanoLinkedin: "",
  oscarTwitter: "",
  oscarLinkedin: "",
  filippoTwitter: "",
  filippoLinkedin: "",
  gonzaloTwitter: "",
  gonzaloLinkedin: "",
  kateTwitter: "",
  kateLinkedin: "",
  matteoTwitter: "",
  matteoLinkedin: "",
  pizzaverseContractAddress: "TBA", // TODO:
  mozzaContractAddress: "TBA", // TODO:
  whitepaperUrl: 'TBA', // TODO:
}

export default allLinks