import { useEffect } from 'react';
import PizzaLove from 'assets/pizzaLove.jpeg';
import { useNavigate } from 'react-router-dom';

const Story: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'STORY | Pizzaverse NFT Collection';
  }, []);

  const handleClickMint = () => {
    navigate('/mint');
  };

  return (
    <div className="px-2 pt-2 bg-background lg:px-6 lg:pt-4">
      <div>
        <div className="flex items-center justify-center">
          <p className="text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
            CHAPTER ONE
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
          <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
          <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        </div>
        <div className="pt-5">
          <p className="pt-4 text-xl font-normal font-varela text-text">
            Marzano is a 30 years old pizzaiolo, he was born on the Amalfi
            coast, in Italy. Behind his dark beard he hides a gentle smile and
            his deep black eyes disclose a trustworthy personality and chill
            character.
          </p>
          <p className="pt-4 text-xl font-normal font-varela text-text">
            His father, Benedetto, was the pizzaiolo of Zuccarossa, the most
            typical Italian village. A beautiful place, near the sea on the
            mountains, not unlike the Cinque Terre.
          </p>
          <p className="pt-4 text-xl font-normal font-varela text-text">
            From a young age Marzano was spending hours and hours looking at his
            dad making pizzas and making the clients happy. During his free
            time, he was helping in the kitchen and delivering pizzas on his red
            bicycle.
          </p>
          <p className="pt-4 text-xl font-normal font-varela text-text">
            Thanks to his dad and the support of his mum, Rossana, Marzano
            learned the best techniques to make the dough, he was quick in
            spotting the ripest tomatoes, in catching the best anchovies, in
            harvesting the best mushrooms, and all the other things which make a
            pizza perfect.
          </p>
          <p className="pt-4 text-xl font-normal font-varela text-text">
            He started his pizzeria at 18 years old, together with his best
            friend Montecarlo and Yana. The voices of his amazing pizza spread
            fast. They reached Naples, Rome, Milan, then Paris, London, Berlin
            and after that Europe was his. Special deliveries were made from
            Portorosso to all around the world, using drones, Teslas and space X
            rockets.
          </p>
          <p className="pt-4 text-xl font-normal font-varela text-text">
            Marzano became the most down to earth celebrity, giving to charities
            most of his money, and making the best pizzas in Europe.
          </p>
        </div>
      </div>
      <div className="pt-10">
        <div className="flex items-center justify-center">
          <p className="text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
            CHAPTER TWO
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
          <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
          <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        </div>
        <div>
          <p className="text-3xl font-normal text-center font-varela text-text">
            Our hero Marzano will soon face a formidable challenge coming from
            the West. He will need your help!
          </p>
          <p className="pt-4 text-3xl font-normal text-center font-varela text-text">
            Stay tuned!
          </p>
        </div>
      </div>
      <div className="pt-10">
        <div className="flex items-center justify-center">
          <p className="text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
            CHAPTER THREE
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
          <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
          <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        </div>
        <div className="pt-5">
          <p className="text-3xl font-normal text-center font-varela text-text">
            COMING SOON...
          </p>
        </div>
      </div>
      <div className="10">
        <div className="pt-5">
          <p className="text-4xl font-semibold text-center font-varela text-title-blue">
            GET A PIZZA
          </p>
          <div className="flex justify-center pt-5">
            <button
              className="px-4 py-3 text-4xl font-semibold text-white uppercase rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
              onClick={handleClickMint}
            >
              MINT IT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
