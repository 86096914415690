import allLinks from 'utils/allLinks';
import PizzaLove from 'assets/pizzaLove.jpeg';
import DiscordPng from 'assets/discord.png';
import TwitterPng from 'assets/twitter.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const handleClickAddress = (link: string) => {
    window.open(link, '_blank');
  };
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();

  const handleClickTerms = () => {
    navigate('/terms');
  };

  const handleClickDiscord = () => {
    navigate('/discord-is-private');
  };

  return (
    <div className="flex justify-center w-full pt-10 bg-background">
      <div>
        <div className="flex items-center justify-center">
          <div>
            <p className="font-varela text-l text-text">
              Built in the metaverse for you with
            </p>
          </div>
          <div>
            <img src={PizzaLove} alt="pizza-logo" className="w-8 h-8 ml-1" />
          </div>
        </div>
        <div className="flex justify-center pt-4">
          <div onClick={() => handleClickDiscord()}>
            <img
              src={DiscordPng}
              alt="discord-logo"
              className="w-8 h-8 mr-4 cursor-pointer"
            />
          </div>
          <div onClick={() => handleClickAddress(allLinks.pizzaverseTwitter)}>
            <img
              src={TwitterPng}
              alt="twitter-logo"
              className="cursor-pointer w-7 h-7"
            />
          </div>
        </div>
        <div className="flex justify-center pt-4" onClick={handleClickTerms}>
          <p className="underline cursor-pointer font-varela text-l text-text hover:text-title-blue">
            Terms & Conditions
          </p>
        </div>
        <div className="flex justify-center pt-1">
          <p
            className="cursor-pointer font-varela text-l text-text hover:text-title-blue"
            onClick={() =>
              handleClickAddress(allLinks.pizzaverseContractAddress)
            }
          >
            Contract address: {allLinks.pizzaverseContractAddress}
          </p>
        </div>
        {/* <div className="flex justify-center">
          <p
            className="cursor-pointer font-varela text-l text-text hover:text-title-blue"
            onClick={() => handleClickAddress(allLinks.mozzaContractAddress)}
          >
            $MOZZA address: {allLinks.mozzaContractAddress}
          </p>
        </div> */}
        <div className="flex justify-center p-4">
          <p className="font-varela text-l text-text">
            &reg; Pizzaverse {currentYear}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
