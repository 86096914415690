import { ReactNode, useState } from 'react';

interface Props {
  childrenQuestion: ReactNode;
  childrenAnswer: ReactNode;
}

const Accordion = (props: Props) => {
  const { childrenQuestion, childrenAnswer } = props;
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const handleClickAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="pb-3">
      <div
        className="flex items-center justify-between pt-1 cursor-pointer "
        onClick={handleClickAccordion}
      >
        <div>{childrenQuestion}</div>
        <div>
          {!isAccordionOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-title"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-title"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          )}
        </div>
      </div>
      {isAccordionOpen && <div className="pt-2 pb-3">{childrenAnswer}</div>}
    </div>
  );
};

export default Accordion;
