import PizzaLove from 'assets/pizzaLove.jpeg';
import AutoCarousel from 'components/Carousel/Carousel';

const handleClickReport = () => {
  alert('The report will be released soon!');
};

const reportString = (
  <span
    className="underline cursor-pointer text-title-blue"
    onClick={handleClickReport}
  >
    REPORT
  </span>
);

const collectionInfos = [
  {
    title: '1,000',
    body: '1,000 truly random generated Pizzaverse NFTs',
  },
  {
    title: 'BENEFITS',
    body: 'Exclusive art, community, developments',
  },
  {
    title: 'OPTIMIZATION',
    body: 'Our smart contract uses the best practices and minimize gas fees for you!',
  },
  {
    title: 'AUDITED',
    body: `Our smart contract and website are audited in order to be secure and bugs free.`,
  },
  {
    title: 'RIGHTS',
    body: 'Includes creative and commercial rights as long as you hold your NFT',
  },
  {
    title: 'IPFS',
    body: 'Truly decentralized (both images and metadata) using IPFS',
  },
  {
    title: 'COMMUNITY',
    body: 'Active and engaged community that decides the project direction',
  },
];

const tableInfos = [
  {
    title: 'Backgrounds',
    body: '18',
  },
  {
    title: 'Plates',
    body: '17',
  },
  {
    title: 'Crusts',
    body: '13',
  },
  {
    title: 'Bases',
    body: '3',
  },
  {
    title: 'Vegetables',
    body: '15',
  },
  {
    title: 'Meats & fishes',
    body: '20',
  },
  {
    title: 'Add-ons',
    body: '19',
  },
  {
    title: 'Fruits',
    body: '17',
  },
  {
    title: 'Flowers',
    body: '8',
  },
  {
    title: 'Desserts',
    body: '12',
  },
  {
    title: 'Exotics',
    body: '18',
  },
  {
    title: 'Tops',
    body: '5',
  },
  {
    title: 'Special',
    body: '1',
  },
  {
    title: 'Total',
    body: '166',
  },
];

const Collection = () => {
  return (
    <div id="collection">
      <div className="flex items-center justify-center px-2 pt-12 lg:px-6">
        <p className="text-5xl uppercase md:text-7xl font-lovelo text-title">
          COLLECTION
        </p>
      </div>
      <div className="flex items-center justify-between px-2 pt-2 pb-5 bg-background lg:px-6 lg:pt-4">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      <div className="px-2 lg:px-0">
        <AutoCarousel
          numberOfItemsDesktop={5}
          firstImages
          secondImages={false}
        />
      </div>
      <div className="flex flex-wrap justify-center px-2 pt-2 lg:px-6 lg:pt-4">
        {collectionInfos.map((collectionInfo, index) => {
          return (
            <div className="pt-5 lg:w-1/4" key={index}>
              <p className="text-4xl text-center lg:text-5xl font-lovelo text-title">
                {collectionInfo.title}
              </p>
              <p className="px-1 text-xl font-normal text-center lg:text-2xl font-varela text-text">
                {collectionInfo.body} {index === 3 && reportString}
              </p>
            </div>
          );
        })}
      </div>
      <div className="px-2 pt-10 pb-5 lg:px-6">
        <p className="pb-5 text-4xl font-semibold text-center lg:text-5xl font-varela text-title">
          TRAITS
        </p>
        <div className="flex justify-center">
          <div className="flex flex-wrap items-center px-4 py-4 bg-background-orange rounded-xl md:max-w-lg">
            {tableInfos.map((tableInfo, index) => {
              const isLastElement = tableInfos.length - 1 === index;
              return (
                <div className="flex w-full py-1" key={index}>
                  <div className="flex w-1/2 justify start">
                    <p
                      className={`text-xl ${
                        isLastElement ? 'font-bold' : 'font-normal'
                      } text-left lg:text-2xl font-varela text-text`}
                    >
                      {tableInfo.title}
                    </p>
                  </div>
                  <div className="flex justify-end w-1/2">
                    <p
                      className={`text-xl ${
                        isLastElement ? 'font-bold' : 'font-semibold'
                      } text-left lg:text-2xl font-varela text-text`}
                    >
                      {' '}
                      {tableInfo.body}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collection;
