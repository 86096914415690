import { useEffect, useState } from 'react';
import allLinks from 'utils/allLinks';
import getWhitelistedAddresses from 'utils/getWhitelistedAddresses';
import PizzaLove from 'assets/pizzaLove.jpeg';
import { useNavigate } from 'react-router-dom';

interface MyCounter {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Countdown = () => {
  const [isAddressWhitelisted, setIsAddressWhitelised] = useState<
    boolean | undefined
  >(undefined);
  const [addressInput, setAddressInput] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const calculateTimeLeft = (): MyCounter => {
    const mintDate = new Date('MM/DD/YYYY'); // TODO: ADD REAL DATE - MM/DD/YYYY
    const todayDate = new Date();
    const difference = +mintDate - +todayDate;

    if (difference > 0) {
      const timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      return timeLeft;
    }

    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  };

  const [timeLeft, setTimeLeft] = useState<MyCounter>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputString = e.target.value;
    setAddressInput(inputString);
  };

  const handleClickCheck = async () => {
    const response: string[] = await getWhitelistedAddresses(
      addressInput ? addressInput : ''
    );
    setIsAddressWhitelised(response.length > 0);
  };

  const handleClickDiscord = () => {
    navigate('/discord-is-private');
  };

  const rightWhitelistedStatus = () => {
    if (isAddressWhitelisted === undefined) {
      return null;
    }
    if (isAddressWhitelisted === true) {
      return (
        <div className="mt-3 bg-blue-500 rounded-full">
          <p className="py-2 text-xl font-semibold text-center text-white font-varela">
            You are whitelisted!
          </p>
        </div>
      );
    }
    return (
      <div className="mt-3 bg-blue-500 rounded-full">
        <p className="py-2 text-xl font-semibold text-center text-white font-varela">
          You NOT are whitelisted :(
        </p>
      </div>
    );
  };

  const countDownInfos = [
    {
      name: 'DAYS',
      timeToEnd: 'TBA',
    },
    {
      name: 'HOURS',
      timeToEnd: 'TBA',
    },
    {
      name: 'MIN',
      timeToEnd: 'TBA',
    },
    {
      name: 'SEC',
      timeToEnd: 'TBA',
    },
  ];

  return (
    <div className="px-2 py-5 bg-bakground xl:py-20">
      <div className="flex items-center justify-center pt-12">
        <p className="text-5xl uppercase md:text-7xl font-lovelo text-title">
          COUNTDOWN
        </p>
      </div>
      <div className="flex items-center justify-between pt-5">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      <div className="flex justify-center mt-4">
        <div>
          <div className="flex justify-between">
            {countDownInfos.map((countDownInfo, index) => {
              return (
                <div key={index}>
                  <p className="text-xl text-center xl:text-3xl font-varela text-title-blue">
                    {countDownInfo.name}
                  </p>
                  <div className="w-20 h-20 mx-4 xl:w-28 xl:h-28 bg-title-blue rounded-xl">
                    <div className="pt-6 xl:pt-9">
                      <p className="text-4xl text-center text-white font-lovelo">
                        {countDownInfo.timeToEnd}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-5">
            <p className="text-center text-l text-title font-varela">
              Join our{' '}
              <span
                onClick={handleClickDiscord}
                className="underline cursor-pointer font-varela text-title-blue"
              >
                DISCORD
              </span>{' '}
              to stay up to date
            </p>
          </div>
          <div className="pt-5">
            {/* <p className="text-2xl text-title font-varela">
              Check if you are whitelisted, paste your address here:
            </p>
            <div className="flex pt-4">
              <input
                className="w-full px-4 py-3 text-2xl font-normal text-center bg-white rounded-full cursor-pointer text-text font-varela focus:border-transparent"
                placeholder="e.g. 0xe6Ac2b46974850Ef276ACB37A936c90cB6958a53"
                onChange={(e) => handleInput(e)}
              />
              <button
                className="px-4 py-3 ml-4 text-xl font-semibold text-white uppercase rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title disabled:bg-gray-300"
                onClick={handleClickCheck}
              >
                CHECK
              </button>
            </div> */}
          </div>
          {/* {rightWhitelistedStatus()}{' '} */}
        </div>
      </div>
    </div>
  );
};

export default Countdown;
