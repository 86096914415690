import PizzaLove from 'assets/pizzaLove.jpeg';

import ApricotImage from 'assets/roadmap/apricot_icon.png';
import AvocadoImage from 'assets/roadmap/avocado_icon.png';
import CuredHamImage from 'assets/roadmap/cured_ham_icon.png';
import EggIcon from 'assets/roadmap/egg_icon.png';
import OctopusImage from 'assets/roadmap/octopus_icon.png';
import SalamiImage from 'assets/roadmap/salami_icon.png';
import ShrimpImage from 'assets/roadmap/shrimp_icon.png';
import ZucchiniImage from 'assets/roadmap/zucchini_icon.png';
import SunflowerImage from 'assets/roadmap/sunflower_icon.png';
import StartfishImage from 'assets/roadmap/starfish_icon.png';
import RedChiliImage from 'assets/roadmap/red_chili_icon.png';

import CrabImage from 'assets/roadmap/crab_icon.png';
import MangoImage from 'assets/roadmap/mango_icon.png';
import RoseImage from 'assets/roadmap/rose_icon.png';

const roadmapInfos = [
  // {
  //   text: 'Random giveaway 5 NFTs for early adopters',
  //   percentage: '10% sales',
  //   imageTopping: ApricotImage,
  // },
  {
    text: 'Pizza Challenge with 5 NFTs for the most creative homemade pizzas',
    percentage: '20% sales',
    imageTopping: AvocadoImage,
  },
  {
    text: 'We randomly give 1 ETH to a Pizzaverse NFT holder',
    percentage: '40% sales',
    imageTopping: CuredHamImage,
  },
  // {
  //   text: 'Two members of the Pizzaverse team will do a pizza tattoo chosen by the community',
  //   percentage: '50% sales',
  //   imageTopping: EggIcon,
  // },
  {
    text: 'Merch store begins construction',
    percentage: '60% sales',
    imageTopping: OctopusImage,
  },
  {
    text: 'CEO Marzano gets a tattoo chosen by the community',
    percentage: '80% sales',
    imageTopping: EggIcon,
  },
  // {
  //   text: 'Partnerships with restaurants that will provide amazing perks and discounts for the holders',
  //   percentage: '80% sales',
  //   imageTopping: ShrimpImage,
  // },
  // {
  //   text: 'Collaboration with food delivery companies begins',
  //   percentage: '90% sales',
  //   imageTopping: ZucchiniImage,
  // },
  {
    text: 'To celebrate 100% in sales we are going to allocate 80% of the remaning sales into food charities chosen by the commmunity',
    percentage: '🎉 SOLD OUT',
    imageTopping: SunflowerImage,
  },
];

const daoInfos = [
  {
    text: 'The community will choose independently the future development of the project',
    image: StartfishImage,
  },
  // {
  //   text: 'First cloud kitchen opens',
  //   image: RedChiliImage,
  // },
  // {
  //   text: 'Decentralized website is launched',
  //   image: CrabImage,
  // },
  // {
  //   text: '$MOZZA ERC-20 token is launched and airdropped to NFT holders',
  //   image: MangoImage,
  // },
  // {
  //   text: 'Chapter 3 is revealed',
  //   image: RoseImage,
  // },
];

const Roadmap = () => {
  return (
    <div id="roadmap">
      <div className="flex items-center justify-center pt-12">
        <p className="text-5xl uppercase md:text-7xl font-lovelo text-title">
          ROADMAP
        </p>
      </div>
      <div className="flex items-center justify-between pt-5">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      <div className="px-2 pt-2 lg:px-6 lg:pt-4">
        {/* <!-- PRE-DAO component --> */}
        <div>
          <div className="flex justify-center mb-4 lg:mb-0">
            <div className="order-1 w-5/12 px-6 py-4 rounded-lg shadow-xl bg-title-blue">
              <p className="text-2xl font-semibold text-center text-white font-varela">
                Sales begins in Q2 2022
              </p>
            </div>
          </div>
          <div className="container w-full h-full mx-auto bg-background">
            <div className="relative h-full p-0 overflow-hidden lg:p-10 wrap">
              <div className="absolute h-full border border-title border-2-2 border-opacity-20 left-1/2"></div>

              {roadmapInfos.map((roadmapInfo, index) => {
                const shouldBeDisplayedLeft = index % 2 === 0;

                return (
                  <div key={index}>
                    {/* <!-- left timeline --> */}
                    {shouldBeDisplayedLeft && (
                      <div className="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline">
                        <div className="order-1 w-5/12"></div>
                        <div className="z-20 flex items-center order-1 w-12 h-12 rounded-full shadow-xl">
                          <img
                            src={roadmapInfo.imageTopping}
                            alt="roadmap_logo"
                          />
                        </div>
                        <div className="order-1 w-5/12 px-3 py-2 rounded-lg shadow-xl bg-background-orange lg:px-6 lg:py-4">
                          <h3 className="mb-3 text-xl font-bold text-text lg:text-2xl font-varela">
                            {roadmapInfo.percentage}
                          </h3>
                          <p className="text-sm font-medium leading-snug tracking-wide text-text lg:text-xl font-varela">
                            {roadmapInfo.text}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* <!-- right timeline --> */}
                    {!shouldBeDisplayedLeft && (
                      <div className="flex items-center justify-between w-full mb-8 right-timeline">
                        <div className="order-1 w-5/12"></div>
                        <div className="z-20 flex items-center order-1 w-10 h-10 rounded-full lg:w-12 lg:h-12">
                          <img
                            src={roadmapInfo.imageTopping}
                            alt="roadmap_logo"
                          />
                        </div>
                        <div className="order-1 w-5/12 px-3 py-2 rounded-lg shadow-xl bg-background-orange lg:px-6 lg:py-4">
                          <h3 className="mb-3 text-xl font-bold text-text lg:text-2xl font-varela">
                            {roadmapInfo.percentage}
                          </h3>
                          <p className="text-sm leading-snug text-text lg:text-xl font-varelatracking-wide">
                            {roadmapInfo.text}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <!-- POST-DAO component --> */}
        <div className="flex justify-center mt-">
          <div>
            <div className="px-6 py-4 mb-4 border-4 rounded-lg shadow-xl border-title">
              <p className="text-2xl font-semibold text-center text-title font-varela">
                SOLD-OUT PHASE STARTS
              </p>
            </div>
            <div>
              {daoInfos.map((daoInfo, index) => {
                return (
                  <div className="flex pt-6 align-middle" key={index}>
                    <img
                      alt="roadmap_log"
                      src={daoInfo.image}
                      className="w-10 h-10 lg:w-12 lg:h-12"
                    />
                    <p className="pl-2 text-2xl text-title font-varela">
                      {daoInfo.text}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
