import BannerImage from 'assets/banner.jpeg';
import Gif from 'assets/randomImages.gif';
import PizzaLove from 'assets/pizzaLove.jpeg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import allLinks from 'utils/allLinks';

const Banner = () => {
  const words = [
    'NFT',
    // 'DAO',
    'ART',
    // 'COMMUNITY',
    // 'RESTAURANT',
    // 'CLOUD KITCHENS',
    // 'UTILITY',
    'STORY',
    'FOOD',
    // '$MOZZA',
    'LOVE',
    'FUTURE',
  ];

  const [wordIndex, setWordIndex] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (wordIndex === words.length - 1) {
      const interval = setInterval(() => setWordIndex(0), 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      const interval = setInterval(() => setWordIndex(wordIndex + 1), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [wordIndex, words.length]);

  const handleClickStory = () => {
    navigate('/story');
  };

  const handleClickDao = () => {
    window.open(allLinks.daoExplanation, '_blank');
  };

  const handleClickCloudKitches = () => {
    window.open(allLinks.cloudKitchesExaplation, '_blank');
  };

  const handleClickMichelin = () => {
    window.open(allLinks.michelinRestaurant, '_blank');
  };

  return (
    <div className="px-2 pt-2 bg-background lg:px-6 lg:pt-4">
      <div className="relative z-0">
        <img alt="banner" src={BannerImage} />
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="text-2xl text-center bg-black bg-opacity-50 lg:px-3 lg:py-3 lg:text-7xl text-title font-varela ali">
            Pizzaverse is{' '}
            <span className="text-yellow-400 font-varela">
              {words[wordIndex]}
            </span>
          </p>
        </div>
      </div>
      <div>
        <div className="pt-10 sm:block lg:flex">
          <div>
            <div className="pb-5 lg:w-2/3">
              <div className="flex items-center justify-center">
                <p className="text-5xl uppercase md:text-7xl font-lovelo text-title">
                  WELCOME
                </p>
              </div>
              <div className="flex items-center justify-between pt-5">
                <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
                <img
                  alt="love-piza"
                  src={PizzaLove}
                  className="w-20 h-20 mx-11"
                />
                <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
              </div>
            </div>
            <div className="sm:block lg:flex">
              <div className="lg:w-2/3">
                <p className="pb-10 text-3xl font-varela text-text">
                  Pizzaverse is a collection of 1,000 randomly generated tasty
                  NFTs. As any freshly baked pizza, each collectible is a unique
                  piece of art.
                </p>
                <p className="pb-10 text-3xl font-varela text-text">
                  By minting a pizza, you are getting access to the thriving
                  Pizzaverse community and gain the power to decide the future
                  development of the project.
                </p>
                <p className="pb-10 text-3xl font-varela text-text">
                  Pizzaverse allocates most of the revenues generated by the
                  primary sales into food charities chosen by the community.
                </p>
                {/* <p className="pb-10 text-2xl font-varela text-text">
                  Pizzaverse aims to allocate part of the primary sales to open
                  a{' '}
                  <span
                    onClick={handleClickMichelin}
                    className="font-semibold underline cursor-pointer text-title hover:text-title-blue"
                  >
                    Michelin Restaurant<sup>(i)</sup>
                  </span>{' '}
                  and into{' '}
                  <span
                    onClick={handleClickCloudKitches}
                    className="font-semibold underline cursor-pointer text-title hover:text-title-blue"
                  >
                    cloud kitchens<sup>(i)</sup>
                  </span>
                  . The community will decide how these cloud kitchens will
                  operate and be managed through voting of members’ proposals.
                </p>
                <p className="pb-10 text-2xl font-varela text-text">
                  Each NFT holder receives a free meal from the restaurants in
                  form of voucher. The NFT generates additional vouchers
                  (ranging from free meal to complementary drink) after a
                  predetermined amount of time. The more a holder holds the NFT
                  for (or stakes it), the more valuable is the ticket he gets.
                  These tickets can be sold using FIAT (or crypto) using our
                  platform.
                </p>
                <p className="pb-10 text-2xl font-varela text-text">
                  In addition, the Pizzaverse NFT acts as an ownership token for
                  the benefits generated by the project and as a{' '}
                  <span
                    onClick={handleClickDao}
                    className="font-semibold underline cursor-pointer text-title hover:text-title-blue"
                  >
                    DAO
                    <sup>(i)</sup>
                  </span>{' '}
                  for the kitchens.
                </p>
                <p className="pb-10 text-2xl font-varela text-text">
                  <span className="font-semibold text-title ">
                    $MOZZA token
                  </span>{' '}
                  will be airdropped to every pizzaverse NFT member. It will act
                  as a utility token for the project.
                </p> */}

                <p className="pb-10 text-3xl font-varela text-text">
                  Don't forget to read our fun{' '}
                  <span
                    onClick={handleClickStory}
                    className="font-semibold underline cursor-pointer text-title hover:text-title-blue"
                  >
                    story
                  </span>
                  , you won't be disappointed.
                </p>
              </div>
              <div className="justify-center pl-0 lg:flex lg:pl-10 2xl:pl-0 lg:w-1/3">
                <div className="my-image-small xl:-mt-10">
                  <img src={Gif} alt="pizzas-gif" className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
