import PizzaLove from 'assets/pizzaLove.jpeg';
import { Accordion } from 'components/Accordion';
import { useNavigate } from 'react-router-dom';

import constants from 'utils/constants';
import allLinks from 'utils/allLinks';

const Faq = () => {
  const navigate = useNavigate();
  const handleClickStory = () => {
    navigate('/story');
  };

  const handleClickToMintPage = () => {
    navigate('/mint');
  };

  const keyInfos = [
    {
      title: 'Total number of tokens:',
      body: '1,000',
    },
    {
      title: 'Price per token:',
      body: `Public sale: ${constants.ethNftPrice}ETH + optimize gas`,
    },
    {
      title: 'Token type:',
      body: 'ERC-721a',
    },
    {
      title: 'Blockchain:',
      body: 'Ethereum',
    },
    {
      title: 'File hosting:',
      body: 'IPFS',
    },
    {
      title: 'Royalty fees:',
      body: '3%',
    },
    {
      title: 'Number of traits:',
      body: '13',
    },
    {
      title: 'Number of attributes:',
      body: '> 150',
    },
    {
      title: 'Licensing:',
      body: 'Commercial rights',
    },
    {
      title: 'Sale type:',
      body: 'Pre-sale and public sale',
    },
    {
      title: 'Smart contract address:',
      body: `${allLinks.pizzaverseContractAddress}`,
    },
  ];

  const benefitsOfPizzaverse = [
    '● You will contribute in donating to food charities and alleviate hunger across the World',
    '● Your NFT will act as a membership card that will give you access to the exciting member-only Pizzaverse community',
    // '● You will be part of the development of the first community-led cloud kitchens project in the world',
    '● You will decide how and where the community is headed in the future',
    // '● You will receive the $MOZZA airdrops',
    '● Each NFT grants you commercial rights to express your creativity and art with the Pizzaverse NFT',
  ];

  const ourStoryAnswer = (
    <p className="text-xl font-normal text-righ lg:text-xl text-text font-varela">
      Read our story{' '}
      <span
        className="underline cursor-pointer text-title-blue font-varela"
        onClick={handleClickStory}
      >
        HERE
      </span>
    </p>
  );

  const nftKeyInfoAnswer = (
    <div>
      {keyInfos.map((info, index) => {
        return (
          <div className="pt-1 lg:flex" key={index}>
            <p className="text-xl font-semibold lg:text-xl text-text font-varela">
              {info.title}
            </p>
            <p className="text-xl font-normal lg:text-xl lg:ml-3 text-text font-varela">
              {info.body}
            </p>
          </div>
        );
      })}
    </div>
  );

  const benefits = (
    <div>
      {benefitsOfPizzaverse.map((benefit, index) => {
        return (
          <p
            className="pt-3 text-xl font-normal lg:text-xl text-text font-varela"
            key={index}
          >
            {benefit}
          </p>
        );
      })}
    </div>
  );

  const faqs = [
    {
      question: <p className="text-2xl text-title font-varela">Our story</p>,
      answer: ourStoryAnswer,
    },
    {
      question: (
        <p className="text-2xl text-title font-varela">
          Pizzaverse NFTs key info
        </p>
      ),
      answer: nftKeyInfoAnswer,
    },
    {
      question: (
        <p className="text-2xl text-title font-varela">
          Which are the benefits of owning a Pizzaverse NFT?
        </p>
      ),
      answer: benefits,
    },
    {
      question: (
        <p className="text-2xl text-title font-varela">When is the launch?</p>
      ),
      answer: (
        <p className="text-xl font-normal text-text font-varela">
          Sales start in Q2 2022. We are working tirelessly to set up the best
          experience we can offer you
        </p>
      ),
    },
    {
      question: (
        <p className="text-2xl text-title font-varela">
          How much does a Pizzaverse NFT cost?
        </p>
      ),
      answer: (
        <div>
          <p className="text-xl font-normal lg:text-xl text-text font-varela">
            Public sale: {constants.ethNftPrice} ETH + optimize gas fees
          </p>
        </div>
      ),
    },
    {
      question: (
        <p className="text-2xl text-title font-varela">
          How do you manage security at Pizzaverse?
        </p>
      ),
      answer: (
        <div>
          <p className="text-xl font-normal lg:text-xl text-text font-varela">
            At Pizzaverse we take securiry very serious - no pizza gets burned
            in the oven! The security of our smart contract and web app is
            audited by best-in-class cyber security experts and the results will
            be shared publicly before the launch.
          </p>
        </div>
      ),
    },
    {
      question: (
        <p className="text-2xl text-title font-varela">
          How can I buy a Pizzaverse NFT?
        </p>
      ),
      answer: (
        <p className="text-xl font-normal lg:text-xl text-text font-varela">
          You can do it directly in the{' '}
          <span
            onClick={handleClickToMintPage}
            className="underline uppercase cursor-pointer font-varela text-title-blue hover:text-title"
          >
            MINT
          </span>{' '}
          page
        </p>
      ),
    },
    // {
    //   question: (
    //     <p className="text-2xl text-title font-varela">
    //       What is the $MOZZA token?
    //     </p>
    //   ),
    //   answer: (
    //     <div>
    //       <p className="text-xl font-normal lg:text-xl text-text font-varela">
    //         $MOZZA is our utility ERC-20 token that will be released after the
    //         Pizzaverse NFT collection.
    //       </p>
    //       <p className="pt-5 text-xl font-normal lg:text-xl text-text font-varela">
    //         <span className="font-semibold">Disclaimers:</span> $MOZZA is the
    //         utility token that fuels the Pizzaverse ecosystem. It is NOT an
    //         investment and has NO economic value. The real value of $MOZZA comes
    //         from the utilities within the Pizzaverse ecosystem (DAOs, cloud
    //         kitches, food parterships, restaurants discounts etc.).
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   question: (
    //     <p className="text-2xl text-title font-varela">
    //       Utility of $MOZZA token
    //     </p>
    //   ),
    //   answer: (
    //     <div>
    //       <p className="text-xl font-normal lg:text-xl text-text font-varela">
    //         Voting, raffles, lottery, customization, DAO, restaurats discounts,
    //         food delivery companies partnerships and much more
    //       </p>
    //       <p className="pt-4 text-xl font-normal lg:text-xl text-text font-varela">
    //         More information will follow soon :)
    //       </p>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div id="faq" className="px-2 pt-4 bg-background lg:px-6 ">
      <div className="flex items-center justify-center">
        <p className="text-5xl uppercase md:text-7xl font-lovelo text-title">
          FAQ
        </p>
      </div>
      <div className="flex items-center justify-between pt-5">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      {faqs.map((faq, index) => {
        return (
          <div key={index}>
            <Accordion
              childrenAnswer={faq.answer}
              childrenQuestion={faq.question}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Faq;
