import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleClickToHomepage = () => {
    navigate('/');
  };

  return (
    <div className="flex justify-center h-screen px-2 py-2 bg-background">
      <div>
        <p className="pt-5 text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
          Oh no! Something went wrong.
        </p>
        <p className="pt-5 text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
          Go back to the homepage
        </p>
        <button
          className="w-full px-4 py-3 mt-10 text-4xl font-semibold text-white rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
          onClick={handleClickToHomepage}
        >
          GO TO THE HOMEPAGE
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
