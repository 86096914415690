import { useEffect, useState } from 'react';
import allLinks from 'utils/allLinks';
import DiscordPng from 'assets/discord.png';
import TwitterPng from 'assets/twitter.png';
import { useLocation, useNavigate } from 'react-router-dom';
import truncateAddress from 'utils/truncateAddress';
import useComponentVisible from 'hooks/useComponentVisible';

declare global {
  interface Window {
    ethereum?: any;
  }
}
interface Props {
  currentAccount?: string;
  isMetamaskMissing?: boolean;
  onClickConnect?: () => void;
  onLoadChain?: (chainId: string) => void;
  onChangeAccount?: (chainId: string) => void;
}

const Navbar = (props: Props) => {
  const {
    currentAccount,
    isMetamaskMissing,
    onClickConnect,
    onLoadChain,
    onChangeAccount,
  } = props;
  const navigate = useNavigate();

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);
  const [isMouseOnAccount, setIsMouseOnAccount] = useState<boolean>(false);

  const handleClickHome = () => {
    navigate('/');
    setIsBurgerMenuOpen(false);
  };
  const handleClickStory = () => {
    navigate('/story');
    setIsBurgerMenuOpen(false);
  };
  const handleClickMint = () => {
    navigate('/mint');
    setIsBurgerMenuOpen(false);
  };

  const handleClickBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleClickDiscord = () => {
    navigate('/discord-is-private');
  };

  const handleClickTwitter = () => {
    window.open(allLinks.pizzaverseTwitter, '_blank');
  };

  // const handleClickWhitepaper = () => {
  //   window.open(allLinks.whitepaper, '_blank');
  // };

  const handleClickConnect = () => {
    onClickConnect && onClickConnect();
  };

  const handleClickLogout = () => {
    window.location.reload();
  };

  const handleMouseEnterAccount = () => {
    setIsMouseOnAccount(true);
  };
  const handleMouseLeaveAccount = () => {
    setIsMouseOnAccount(false);
  };

  const scrollToSection = (idName: string) => {
    const divElement = document.getElementById(idName.toLowerCase());
    divElement!.scrollIntoView({ behavior: 'smooth' });
  };

  const NAVBAR_LINKS = [
    {
      name: 'HOME',
      onClickLink: () => handleClickHome(),
      pagesToDisplay: ['/story', '/terms', '/mint'],
    },
    {
      name: 'STORY',
      onClickLink: () => handleClickStory(),
      pagesToDisplay: ['/', '/mint', '/terms'],
    },
    {
      name: 'COLLECTION',
      onClickLink: () => {
        scrollToSection('collection');
        setIsBurgerMenuOpen(false);
      },
      pagesToDisplay: ['/'],
    },
    {
      name: 'ROADMAP',
      onClickLink: () => {
        scrollToSection('roadmap');
        setIsBurgerMenuOpen(false);
      },
      pagesToDisplay: ['/'],
    },
    {
      name: 'TEAM',
      onClickLink: () => {
        scrollToSection('team');
        setIsBurgerMenuOpen(false);
      },
      pagesToDisplay: ['/'],
    },
    // {
    //   name: 'PARTNERS',
    //   onClickLink: () => {
    //     scrollToSection('partners');
    //     setIsBurgerMenuOpen(false);
    //   },
    //   pagesToDisplay: ['/'],
    // },
    {
      name: 'FAQ',
      onClickLink: () => {
        scrollToSection('faq');
        setIsBurgerMenuOpen(false);
      },
      pagesToDisplay: ['/'],
    },
    // {
    //   name: 'WHITEPAPER',
    //   onClickLink: () => {
    //     setIsBurgerMenuOpen(false);
    //     handleClickWhitepaper();
    //   },
    //   pagesToDisplay: ['/'],
    // },
  ];

  const currentLocationPath = useLocation();

  const checkingShouldBeDisplayed = (listOfPages: string[]) => {
    if (listOfPages.includes(currentLocationPath.pathname)) {
      return true;
    }

    return false;
  };

  const { ref, isComponentVisible } = useComponentVisible(true);

  useEffect(() => {
    setIsBurgerMenuOpen(false);
  }, [isComponentVisible]);

  useEffect(() => {
    if (window.ethereum) {
      const chainId = window.ethereum.networkVersion;
      onLoadChain && onLoadChain(chainId);
    } else {
      console.log('You need Metamask in your Browser :)');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chainId: string) => {
        onLoadChain && onLoadChain(chainId);
      });
    } else {
      console.log('You need Metamask in your Browser :)');
    }
  }, [onLoadChain]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts: string) => {
        onChangeAccount && onChangeAccount(accounts[0]);
      });
    } else {
      console.log('You need Metamask in your Browser :)');
    }
  }, [onChangeAccount]);

  return (
    <>
      {/* DESKTOP */}
      <div className="px-2 pt-2 lg:px-6 lg:pt-4 bg-background">
        <div className="items-center justify-between hidden xl:flex">
          <div className="cursor-pointer xl:w-300" onClick={handleClickHome}>
            <p className="text-6xl uppercase font-lovelo text-title-blue">
              PIZZA
            </p>
            <p className="text-6xl uppercase font-lovelo text-title-blue">
              VERSE
            </p>
          </div>
          <div className="flex">
            {NAVBAR_LINKS.map((navbarLink, index) => {
              const shouldBeDisplayed = checkingShouldBeDisplayed(
                navbarLink.pagesToDisplay
              );

              if (shouldBeDisplayed) {
                return (
                  <p
                    className="px-5 text-4xl font-semibold uppercase cursor-pointer font-varela text-title hover:text-title-blue"
                    onClick={navbarLink.onClickLink}
                    key={index}
                  >
                    {navbarLink.name}
                  </p>
                );
              }
              return null;
            })}
          </div>
          <div className="flex justify-end xl:w-300">
            {!(currentLocationPath.pathname === '/mint') && (
              <div className="items-center justify-center mr-4">
                <button
                  className="px-4 py-3 text-4xl font-semibold text-white uppercase rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
                  onClick={handleClickMint}
                >
                  MINT IT
                </button>
              </div>
            )}
            {currentLocationPath.pathname === '/mint' && (
              <div className="items-center justify-center mr-4">
                {currentAccount ? (
                  <div>
                    <button
                      className="px-4 py-3 text-4xl font-semibold text-white rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
                      onClick={handleClickLogout}
                      onMouseEnter={handleMouseEnterAccount}
                      onMouseLeave={handleMouseLeaveAccount}
                    >
                      {isMouseOnAccount
                        ? 'LOGOUT'
                        : truncateAddress(currentAccount)}
                    </button>
                  </div>
                ) : (
                  <button
                    className="px-4 py-3 text-4xl font-semibold text-white rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
                    onClick={handleClickConnect}
                  >
                    CONNECT
                  </button>
                )}
              </div>
            )}
            <div className="flex justify-center align-middle">
              <div>
                <div
                  className="w-8 h-8 -mt-1 cursor-pointer"
                  onClick={handleClickDiscord}
                >
                  <img src={DiscordPng} alt="discord-logo" />
                </div>
                <div
                  className="w-8 h-8 pt-1 cursor-pointer"
                  onClick={handleClickTwitter}
                >
                  <img src={TwitterPng} alt="twitter-logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div className="px-2 pt-2 lg:px-6 lg:pt-4 bg-background xl:hidden">
        <div className="flex items-center justify-between ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer w-9 h-9 text-title hover:text-title-blue"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleClickBurgerMenu}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
          {!(currentLocationPath.pathname === '/mint') && (
            <div className="items-center justify-center">
              <button
                className="px-4 py-3 text-2xl font-semibold text-white uppercase rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
                onClick={handleClickMint}
              >
                MINT IT
              </button>
            </div>
          )}
          {currentLocationPath.pathname === '/mint' && (
            <div className="items-center justify-center">
              {currentAccount ? (
                <button
                  className="px-4 py-3 text-4xl font-semibold text-white rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
                  onClick={handleClickLogout}
                  onMouseEnter={handleMouseEnterAccount}
                  onMouseLeave={handleMouseLeaveAccount}
                >
                  {isMouseOnAccount
                    ? 'LOGOUT'
                    : truncateAddress(currentAccount)}
                </button>
              ) : (
                <button
                  className="px-4 py-3 text-2xl font-semibold text-white uppercase rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
                  onClick={handleClickConnect}
                >
                  CONNECT
                </button>
              )}
            </div>
          )}
        </div>
        {/* MOBILE MENU */}
        {isBurgerMenuOpen && (
          <div className="items-start lg:hidden" ref={ref}>
            {NAVBAR_LINKS.map((navbarLink, index) => {
              const shouldBeDisplayed = checkingShouldBeDisplayed(
                navbarLink.pagesToDisplay
              );
              if (shouldBeDisplayed) {
                return (
                  <p
                    className="py-1 text-2xl font-semibold uppercase cursor-pointer font-varela text-title hover:text-title-blue"
                    onClick={navbarLink.onClickLink}
                    key={index}
                  >
                    {navbarLink.name}
                  </p>
                );
              }
              return null;
            })}
            <div className="flex mt-2">
              <div
                className="cursor-pointer w-7 h-7"
                onClick={handleClickDiscord}
              >
                <img src={DiscordPng} alt="discord-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={handleClickTwitter}
              >
                <img src={TwitterPng} alt="twitter-logo" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
