const ScrollUpButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="fixed bottom-0 flex justify-end cursor-pointer right-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-10 h-10 text-title hover:text-title-blue"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={scrollToTop}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 15l7-7 7 7"
        />
      </svg>
    </div>
  );
};

export default ScrollUpButton;
