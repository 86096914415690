import Image1 from 'assets/img/img_sample_1.jpg';
import Image2 from 'assets/img/img_sample_2.jpg';
import Image3 from 'assets/img/img_sample_3.jpg';
import Image4 from 'assets/img/img_sample_4.jpg';
import Image5 from 'assets/img/img_sample_5.jpg';
import Image6 from 'assets/img/img_sample_6.jpg';
import Image7 from 'assets/img/img_sample_7.jpg';
import Image8 from 'assets/img/img_sample_8.jpg';
import Image9 from 'assets/img/img_sample_9.jpg';
import Image10 from 'assets/img/img_sample_10.jpg';
import Image11 from 'assets/img/img_sample_11.jpg';
import Image12 from 'assets/img/img_sample_12.jpg';
import Image13 from 'assets/img/img_sample_13.jpg';
import Image14 from 'assets/img/img_sample_14.jpg';
import Image15 from 'assets/img/img_sample_15.jpg';
import Image16 from 'assets/img/img_sample_16.jpg';
import Image17 from 'assets/img/img_sample_17.jpg';
import Image18 from 'assets/img/img_sample_18.jpg';
import Image19 from 'assets/img/img_sample_19.jpg';
import Image20 from 'assets/img/img_sample_20.jpg';
// import Image21 from 'assets/img/img_sample_21.jpg';
// import Image22 from 'assets/img/img_sample_22.jpg';
// import Image23 from 'assets/img/img_sample_23.jpg';
// import Image24 from 'assets/img/img_sample_24.jpg';
// import Image25 from 'assets/img/img_sample_25.jpg';
// import Image26 from 'assets/img/img_sample_26.jpg';
// import Image27 from 'assets/img/img_sample_27.jpg';
// import Image28 from 'assets/img/img_sample_28.jpg';
// import Image29 from 'assets/img/img_sample_29.jpg';
// import Image30 from 'assets/img/img_sample_30.jpg';
// import Image31 from 'assets/img/img_sample_31.jpg';
// import Image32 from 'assets/img/img_sample_32.jpg';
// import Image33 from 'assets/img/img_sample_33.jpg';
// import Image34 from 'assets/img/img_sample_34.jpg';
// import Image35 from 'assets/img/img_sample_35.jpg';
// import Image36 from 'assets/img/img_sample_36.jpg';
// import Image37 from 'assets/img/img_sample_37.jpg';
// import Image38 from 'assets/img/img_sample_38.jpg';
// import Image39 from 'assets/img/img_sample_39.jpg';
// import Image40 from 'assets/img/img_sample_40.jpg';
// import Image41 from 'assets/img/img_sample_41.jpg';
// import Image42 from 'assets/img/img_sample_42.jpg';
// import Image43 from 'assets/img/img_sample_43.jpg';
// import Image44 from 'assets/img/img_sample_44.jpg';
// import Image45 from 'assets/img/img_sample_45.jpg';
// import Image46 from 'assets/img/img_sample_46.jpg';
// import Image47 from 'assets/img/img_sample_47.jpg';
// import Image48 from 'assets/img/img_sample_48.jpg';
// import Image49 from 'assets/img/img_sample_49.jpg';
// import Image50 from 'assets/img/img_sample_50.jpg';
// import Image51 from 'assets/img/img_sample_51.jpg';
// import Image52 from 'assets/img/img_sample_52.jpg';
// import Image53 from 'assets/img/img_sample_53.jpg';
// import Image54 from 'assets/img/img_sample_54.jpg';
// import Image55 from 'assets/img/img_sample_55.jpg';
// import Image56 from 'assets/img/img_sample_56.jpg';
// import Image57 from 'assets/img/img_sample_57.jpg';
// import Image58 from 'assets/img/img_sample_58.jpg';
// import Image59 from 'assets/img/img_sample_59.jpg';
// import Image60 from 'assets/img/img_sample_60.jpg';
// import Image61 from 'assets/img/img_sample_61.jpg';
// import Image62 from 'assets/img/img_sample_62.jpg';
// import Image63 from 'assets/img/img_sample_63.jpg';
// import Image64 from 'assets/img/img_sample_64.jpg';
// import Image65 from 'assets/img/img_sample_65.jpg';
// import Image66 from 'assets/img/img_sample_66.jpg';
// import Image67 from 'assets/img/img_sample_67.jpg';
// import Image68 from 'assets/img/img_sample_68.jpg';
// import Image69 from 'assets/img/img_sample_69.jpg';
// import Image70 from 'assets/img/img_sample_70.jpg';
// import Image71 from 'assets/img/img_sample_71.jpg';
// import Image72 from 'assets/img/img_sample_72.jpg';
// import Image73 from 'assets/img/img_sample_73.jpg';
// import Image74 from 'assets/img/img_sample_74.jpg';
// import Image75 from 'assets/img/img_sample_75.jpg';
// import Image76 from 'assets/img/img_sample_76.jpg';
// import Image77 from 'assets/img/img_sample_77.jpg';
// import Image78 from 'assets/img/img_sample_78.jpg';
// import Image79 from 'assets/img/img_sample_79.jpg';
// import Image80 from 'assets/img/img_sample_80.jpg';
// import Image81 from 'assets/img/img_sample_81.jpg';
// import Image82 from 'assets/img/img_sample_82.jpg';
// import Image83 from 'assets/img/img_sample_83.jpg';
// import Image84 from 'assets/img/img_sample_84.jpg';
// import Image85 from 'assets/img/img_sample_85.jpg';
// import Image86 from 'assets/img/img_sample_86.jpg';
// import Image87 from 'assets/img/img_sample_87.jpg';
// import Image88 from 'assets/img/img_sample_88.jpg';
// import Image89 from 'assets/img/img_sample_89.jpg';
// import Image90 from 'assets/img/img_sample_90.jpg';
// import Image91 from 'assets/img/img_sample_91.jpg';
// import Image92 from 'assets/img/img_sample_92.jpg';
// import Image93 from 'assets/img/img_sample_93.jpg';
// import Image94 from 'assets/img/img_sample_94.jpg';
// import Image95 from 'assets/img/img_sample_95.jpg';
// import Image96 from 'assets/img/img_sample_96.jpg';
// import Image97 from 'assets/img/img_sample_97.jpg';
// import Image98 from 'assets/img/img_sample_98.jpg';
// import Image99 from 'assets/img/img_sample_99.jpg';
// import Image100 from 'assets/img/img_sample_100.jpg';
import Carousel from 'react-multi-carousel';

const firstGroupImg = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  // Image21,
  // Image22,
  // Image23,
  // Image24,
  // Image25,
  // Image26,
  // Image27,
  // Image28,
  // Image29,
  // Image30,
  // Image31,
  // Image32,
  // Image33,
  // Image34,
  // Image35,
  // Image36,
  // Image37,
  // Image38,
  // Image39,
  // Image40,
  // Image41,
  // Image42,
  // Image43,
  // Image44,
  // Image45,
  // Image46,
  // Image47,
  // Image48,
  // Image49,
  // Image50,
];

// const secondGroupImage = [
//   Image51,
//   Image52,
//   Image53,
//   Image54,
//   Image55,
//   Image56,
//   Image57,
//   Image58,
//   Image59,
//   Image60,
//   Image61,
//   Image62,
//   Image63,
//   Image64,
//   Image65,
//   Image66,
//   Image67,
//   Image68,
//   Image69,
//   Image70,
//   Image71,
//   Image72,
//   Image73,
//   Image74,
//   Image75,
//   Image76,
//   Image77,
//   Image78,
//   Image79,
//   Image80,
//   Image81,
//   Image82,
//   Image83,
//   Image84,
//   Image85,
//   Image86,
//   Image87,
//   Image88,
//   Image89,
//   Image90,
//   Image91,
//   Image92,
//   Image93,
//   Image94,
//   Image95,
//   Image96,
//   Image97,
//   Image98,
//   Image99,
//   Image100,
// ];

interface Props {
  numberOfItemsDesktop: number;
  firstImages: boolean;
  secondImages: boolean;
}
const AutoCarousel = (props: Props) => {
  const { numberOfItemsDesktop, firstImages, secondImages } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: numberOfItemsDesktop,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      pauseOnHover={false}
      responsive={responsive}
      ssr={true} // It means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      keyBoardControl={false}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      deviceType="desktop"
    >
      {firstImages &&
        firstGroupImg.map((myImage, index) => {
          return (
            <div key={`myImage-${index}`} className="w-30">
              <img alt={`pizza-${index}`} src={myImage} width="100%" />
            </div>
          );
        })}
      {/* {secondImages &&
        secondGroupImage.map((myImage, index) => {
          return (
            <div key={`myImage-${index}`}>
              <img alt={`pizza-${index}`} src={myImage} width="100%" />
            </div>
          );
        })} */}
    </Carousel>
  );
};

export default AutoCarousel;
