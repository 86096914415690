import PizzaLove from 'assets/pizzaLove.jpeg';
import allLinks from 'utils/allLinks';

import MarzanoImage from 'assets/team/marzano.png';
import MontecarloImage from 'assets/team/montecarlo.png';
import CaterinaImage from 'assets/team/caterina.png';
import OskazImage from 'assets/team/oskaz.png';
import PhillImage from 'assets/team/phill.png';
import MattImage from 'assets/team/matt.png';

import LinkedinPng from 'assets/linkedin.png';
import TwitterPng from 'assets/twitter.png';
import GithubPng from 'assets/github.png';

const handleClickSocialLogo = (socialLogo: string) => {
  window.open(socialLogo, '_blank');
};

const teamInfos = [
  {
    name: 'Marzano',
    position: 'Pizzaiolo/CEO',
    avatar: MarzanoImage,
    description:
      'Pizza lover and crypto degenerate. Experience in software engineering and economics.',
    linkedin: allLinks.marzanoLinkedin,
    twitter: allLinks.marzanoTwitter,
    linkedinPng: LinkedinPng,
    twitterPng: TwitterPng,
    githubPng: GithubPng,
  },
  {
    name: 'Montecarlo',
    position: 'Baker/CTO',
    avatar: MontecarloImage,
    description:
      'Eats pizza with Tequila for breakfast. Senior machine learning engineer with strong solidity skills.',
    linkedin: allLinks.gonzaloLinkedin,
    twitter: allLinks.gonzaloTwitter,
    linkedinPng: LinkedinPng,
    twitterPng: TwitterPng,
    githubPng: GithubPng,
  },
  {
    name: 'Oskaz',
    position: 'Sauce tester/Legal',
    avatar: OskazImage,
    description:
      'Loves chili and deep fried pizza. Experienced lawyer with passion for DeFi, crypto and the human brain.',
    linkedin: allLinks.oscarLinkedin,
    twitter: allLinks.oscarTwitter,
    linkedinPng: LinkedinPng,
    twitterPng: TwitterPng,
    githubPng: GithubPng,
  },
  {
    name: 'Caterina',
    position: 'Sommelier/Cloud kitchens specialist',
    avatar: CaterinaImage,
    description:
      'Keep calm & eat pizza 🍕. Digital Marketing lead with B2B2C experience in FMCG & On-Demand food delivery channels.',
    linkedin: allLinks.kateLinkedin,
    twitter: allLinks.kateTwitter,
    linkedinPng: LinkedinPng,
    twitterPng: TwitterPng,
    githubPng: GithubPng,
  },
  {
    name: '',
    position: '',
    avatar: '',
    description: '',
    linkedin: '',
    twitter: '',
    linkedinPng: '',
    twitterPng: '',
    githubPng: '',
  },
  {
    name: 'Phill',
    position: 'Maître/Business Advisor',
    avatar: PhillImage,
    description:
      'When good pizza is not available, he makes his own. Business developer connecting the dots in the crypto space.',
    linkedin: allLinks.filippoLinkedin,
    twitter: allLinks.filippoTwitter,
    linkedinPng: LinkedinPng,
    twitterPng: TwitterPng,
    githubPng: GithubPng,
  },
  {
    name: 'Matt',
    position: 'Bouncer/Cyber security advisor',
    avatar: MattImage,
    description:
      'No pizza gets stolen under his watch. Cyber security enthusiast inspired by security puzzles and new technology.',
    linkedin: allLinks.matteoLinkedin,
    twitter: allLinks.matteoTwitter,
    linkedinPng: LinkedinPng,
    twitterPng: TwitterPng,
    githubPng: GithubPng,
  },
];

const Team = () => {
  return (
    <div id="team" className="px-2 pt-5 bg-background lg:px-6 lg:pt-10">
      <div className="flex items-center justify-center">
        <p className="pt-2 text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
          TEAM
        </p>
      </div>
      <div className="flex items-center justify-between pt-5">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      <div className="grid gap-4 pt-5 lg:grid-cols-4">
        {teamInfos.map((teamInfo, index) => {
          return teamInfo.avatar ? (
            <div key={index}>
              <div className="flex justify-center">
                <div className="w-64 h-64">
                  <img
                    className="rounded-3xl"
                    src={teamInfo.avatar}
                    alt="avatar"
                  />
                </div>
              </div>
              <div>
                <p className="pt-3 text-2xl text-center font-varela text-title">
                  {teamInfo.name}
                </p>
              </div>
              <div>
                <p className="pt-2 text-xl text-center font-varela text-title-blue">
                  {teamInfo.position}
                </p>
              </div>
              <div>
                <p className="pt-2 text-xl font-normal text-center font-varela text-text">
                  {teamInfo.description}
                </p>
              </div>
              <div className="flex items-center justify-center pt-3">
                {teamInfo.linkedin && (
                  <div
                    className="w-8 h-8 cursor-pointer"
                    onClick={() => handleClickSocialLogo(teamInfo.linkedin)}
                  >
                    <img src={teamInfo.linkedinPng} alt="linkedinLogo" />
                  </div>
                )}
                {teamInfo.twitter && (
                  <div
                    className="w-8 h-8 mx-5 cursor-pointer"
                    onClick={() => handleClickSocialLogo(teamInfo.twitter)}
                  >
                    <img src={teamInfo.twitterPng} alt="twitterLogo" />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <span key={index} />
          );
        })}
      </div>
    </div>
  );
};

export default Team;
