import { useNavigate } from 'react-router-dom';
import allLinks from 'utils/allLinks';

const Socials = () => {
  const navigate = useNavigate();
  const handleClickToHomepage = () => {
    navigate('/');
  };

  const handleClickTwitter = () => {
    window.open(allLinks.pizzaverseTwitter, '_blank');
  };

  return (
    <div className="flex justify-center h-screen px-2 py-2 bg-background">
      <div>
        <p className="pt-5 pb-5 text-5xl text-center md:text-7xl font-lovelo text-title">
          It is not easy to be a Pizzaiolo
        </p>
        <p className="pt-10 text-5xl text-center uppercase md:text-7xl font-varela text-title">
          DISCORD IS PRIVATE
        </p>
        <p className="pt-5 text-5xl text-center uppercase md:text-7xl font-varela text-title">
          Follow us on{' '}
          <span
            onClick={handleClickTwitter}
            className="underline cursor-pointer text-title-blue hover:text-title"
          >
            TWITTER
          </span>{' '}
          to join our Discord
        </p>
        <button
          className="w-full px-4 py-3 mt-10 mb-10 text-4xl font-semibold text-white rounded-full cursor-pointer font-varela bg-title-blue hover:bg-title"
          onClick={handleClickToHomepage}
        >
          GO TO THE HOMEPAGE
        </button>
      </div>
    </div>
  );
};

export default Socials;
