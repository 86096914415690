const getWhitelistedAddresses = async (currentAccount: string) => {

  if (!currentAccount) return [] // TODO: Empty array means not whitelisted
  const res = await fetch(
    `https://a0a0wmqym7.execute-api.eu-west-1.amazonaws.com/proof/${currentAccount}`,
    {
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: null,
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    }
  );
  return res.json();
};

export default getWhitelistedAddresses