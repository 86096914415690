import { useEffect } from 'react';
import PizzaLove from 'assets/pizzaLove.jpeg';

const Terms: React.FC = () => {
  useEffect(() => {
    document.title = 'T&C | Pizzaverse NFT Collection';
  }, []);

  return (
    <div className="px-2 pt-2 bg-background lg:px-6 lg:pt-4">
      <div className="flex items-center justify-center">
        <p className="text-5xl text-center uppercase md:text-7xl font-lovelo text-title">
          TERMS AND CONDITIONS
        </p>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
        <img alt="love-piza" src={PizzaLove} className="w-20 h-20 mx-11" />
        <div className="w-3/4 h-5 border-t-4 border-b-4 border-title" />
      </div>
      <div>
        <p className="py-4 font-normal text-l font-varela text-text">
          Last updated on October 21, 2021
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          Pizzaverse is a collection of digital artworks (NFTs) running on the
          Ethereum network. This website is only an interface allowing
          participants to buy digital collectibles. Users are entirely
          responsible for the safety and management of their own private
          Ethereum wallets and validating all transactions and contracts
          generated by this website before approval. Furthermore, as the
          Pizzaverse smart contract runs on the Ethereum network, there is no
          ability to undo, reverse, or restore any transactions. This website
          and its connected services are provided “as is'' and “as available”
          without warranty of any kind. By using this website you are accepting
          sole responsibility for any and all transactions involving Pizzaverse
          digital collectibles. These terms and conditions constitute a legally
          binding agreement (the “Agreement”) between you (also referred to
          herein as “You”, “Your” or “User”) and Pizzaverse Labs ( “we” or
          “us”), governing your purchase of NFTs as part of the Pizzaverse NFT
          Collection Offering (the “Offering”). BY PARTICIPATING IN THIS
          OFFERING, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL OF THE TERMS
          INCORPORATED HEREIN. If you do not agree to the terms of this
          Agreement you may not participate in the Offering. By entering into
          this Agreement, and/or by you participating in the Pizzaverse NFT
          Offering, and/or by you clicking the "MINT" button, you expressly
          acknowledge that you understand this Agreement and accept all of its
          terms. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF
          THIS AGREEMENT, YOU MAY NOT PARTICIPATE IN THE OFFERING.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          1. OWNERSHIP
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          You Own the NFT. Each Pizzaverse is an NFT on the Ethereum blockchain.
          When you purchase an NFT, you own the underlying Pizzaverse NFT, the
          Art, completely. Ownership of the NFT is mediated entirely by the
          Smart Contract and the Ethereum Network: at no point may we seize,
          freeze, or otherwise modify the ownership of any Pizzaverse. ii.
          Personal Use. Subject to your continued compliance with these Terms,
          Pizzaverse Labs grants you a worldwide, royalty-free license to use,
          copy, and display the purchased Art, along with any extensions that
          you choose to create or use, solely for the following purposes: (i)
          for your own personal, non-commercial use; (ii) as part of a
          marketplace that permits the purchase and sale of your Pizzaverse /
          NFT, provided that the marketplace cryptographically verifies each
          Pizzaverse owner’s rights to display the Art for their Pizzaverse to
          ensure that only the actual owner can display the Art; or (iii) as
          part of a third party website or application that permits the
          inclusion, involvement, or participation of your Pizzaverse, provided
          that the website/application cryptographically verifies each
          Pizzaverse owner’s rights to display the Art for their Pizzaverse to
          ensure that only the actual owner can display the Art, and provided
          that the Art is no longer visible once the owner of the Pizzaverse
          leaves the website/application. iii. Commercial Use. Subject to your
          continued compliance with these Terms, Pizzaverse Labs grants you an
          unlimited, worldwide license to use, copy, and display the purchased
          Art for the purpose of creating derivative works based upon the Art
          (“Commercial Use”). Examples of such Commercial Use would e.g. be the
          use of the Art to produce and sell merchandise products (T-Shirts
          etc.) displaying copies of the Art. For the sake of clarity, nothing
          in this Section will be deemed to restrict you from (i) owning or
          operating a marketplace that permits the use and sale of Pizzaverse
          generally, provided that the marketplace cryptographically verifies
          each Pizzaverse owner’s rights to display the Art for their Pizzaverse
          to ensure that only the actual owner can display the Art; (ii) owning
          or operating a third party website or application that permits the
          inclusion, involvement, or participation of Pizzaverse generally,
          provided that the third party website or application cryptographically
          verifies each Pizzaverse owner’s rights to display the Art for their
          Pizzaverse to ensure that only the actual owner can display the Art,
          and provided that the Art is no longer visible once the owner of the
          Purchased Pizzaverse leaves the website/application; or (iii) earning
          revenue from any of the foregoing.{' '}
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          2. DISCLAIMER OF WARRANTIES & LIMITATION OF LIABILITY.
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          ALL NFTs ARE PROVIDED “AS IS'' AND “AS AVAILABLE” WITHOUT WARRANTIES
          OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
          PERMISSIBLE PURSUANT TO APPLICABLE LAW, Pizzaverse LABS DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
          WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
          PARTICULAR PURPOSE. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE
          LAW, IN NO EVENT SHALL Pizzaverse LABS BE LIABLE TO YOU FOR ANY
          PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE
          GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE,
          COMPUTER AND/OR DEVICE OR TECHNOLOGY FAILURE OR MALFUNCTION, OR FOR
          ANY FORM OF DIRECT OR INDIRECT DAMAGES, AND/OR ANY SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES BASED ON ANY
          CAUSES OF ACTION WHATSOEVER RELATED TO ANY NFT, INCLUDING BUT NOT
          LIMITED TO A Pizzaverse NFT, THE AUCTION, ANY TECHNOLOGY AND/OR
          PARTIES RELATED TO THE AUCTION, INCLUDING BUT NOT LIMITED TO
          BLOCKCHAIN, METAMASK WALLET AND/OR OZONE NETWORKS, INC D/B/A OPENSEA.
          YOU AGREE THAT THIS LIMITATION OF LIABILITY APPLIES WHETHER SUCH
          ALLEGATIONS ARE FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE,
          OR FALL UNDER ANY OTHER CAUSE OF ACTION, REGARDLESS OF THE BASIS UPON
          WHICH LIABILITY IS CLAIMED AND EVEN IF A DISCLAIMING PARTY HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE, AND IN ANY EVENT,
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Pizzaverse LABS
          TOTAL AGGREGATE LIABILITY SHALL NOT EXCEED TEN PERCENT (10%) OF THE
          TOTAL SUM PAID DIRECTLY BY YOU TO Pizzaverse LABS FOR THE APPLICABLE
          Pizzaverse NFT. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
          INFORMATION AND DEALING ONLINE OVER THE INTERNET AND AGREE THAT WE
          HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS
          IT IS DUE TO OUR GROSS NEGLIGENCE. IF APPLICABLE LAW DOES NOT ALLOW
          ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU,
          THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY
          APPLICABLE LAW.{' '}
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          3. Assumption Of Risk
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          You agree that You assume the following risks: (A) To the extent there
          is a price or market for a blockchain asset such as an NFT, such
          markets and prices are extremely volatile, and variations in the price
          of other digital assets could materially and adversely affect the
          value of any digital asset(s) You own, including Your Pizzaverse NFT,
          and there is no guarantee that Pizzaverse NFTs will have or retain any
          value; (B) the commercial or market value on a Pizzaverse NFT that You
          purchase may materially diminish in value as a result of a variety of
          things such as negative publicity; (C) there are risks associated with
          using an Internet-native assets (e.g., non-fungible tokens,
          cryptocurrencies, etc.) including, but not limited to, the risk of
          hardware, software and Internet connections and/or failures, the risk
          of malicious software introduction, and the risk that third parties
          may obtain unauthorized access to information stored within your
          digital “wallet” or elsewhere, and Pizzaverse Labs will not be
          responsible for any of these, however caused; (D) Pizzaverse Labs does
          not make any promises or guarantees about the availability of the
          Pizzaverse NFTs or the Art on the Internet or that they will host the
          Pizzaverse NFTs or the Art at any specific location and/or for any
          specific period of time; (E) upgrades to the Ethereum platform, a hard
          fork or other change in the Ethereum platform, a failure or cessation
          of Ethereum, or a change in how transactions are confirmed on the
          Ethereum platform may have unintended, adverse effects on all
          blockchains using such technologies, including without limitation
          Pizzaverse NFTs; (F) Pizzaverse Labs does not make any promises or
          guarantees related to MetaMask Wallet, Ozone Networks, Inc. d/b/a
          OpenSea, Blockchain or any other third parties related to this auction
          and each of their applications and/or services, including but not
          limited to the continued availability of either and/or the protection
          and/or storage of any data you provide to those parties; (G) the risk
          of losing access to Licensed NFT due to loss of private key(s),
          custodial error or purchaser error; (H) the risk of mining attacks;
          (I) the risk of hacking, security weaknesses, fraud, counterfeiting,
          cyber attacks and other technological difficulties (J) the risk of
          changes to the regulatory regime governing blockchain technologies,
          cryptocurrencies, and tokens and new regulations, unfavorable
          regulatory intervention in one or more jurisdictions or policies any
          of which may materially adversely affect the use and value of the
          Pizzaverse NFT; (K) the risks related to taxation; (L) that NFTs are
          not legal tender and are not back by any government; and (M)
          Pizzaverse Labs is not responsible for any transaction between you and
          a third party (e.g., Your transfer of a Pizzaverse NFT from a third
          party on the so-called “secondary market”), and Pizzaverse Labs shall
          have no liability in connection with any such transaction. In addition
          to assuming all of the above risks, you acknowledge that You have
          obtained sufficient information to make an informed decision to
          purchase a Pizzaverse NFT and that You understand and agree that you
          are solely responsible for determining the nature, potential value,
          suitability and appropriateness of these risks for yourself.
          Pizzaverse Labs cannot and does not represent or warrant that any
          Pizzaverse NFT, or its supporting systems or technology, is reliable,
          current or error-free, meets Your requirements, or that defects in the
          Pizzaverse NFT, or its supporting systems or technology, will be
          corrected. Pizzaverse Labs cannot and does not represent or warrant
          that the Pizzaverse NFT or the delivery mechanism for it are free of
          viruses or other harmful components. You accept and acknowledge that
          Pizzaverse Labs will not be responsible for any communication
          failures, disruptions, errors, distortions or delays You may
          experience related to the Offering.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          4. Governing Law
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          This Agreement and all matters related to it and/or any Pizzaverse NFT
          shall be governed by, construed, and enforced in accordance with the
          laws of the United Kingdom, as they are applied to agreements entered
          into and to be performed entirely within the United Kingdom and
          without regard to conflict of law principles.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          5. Changes to this Agreement
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          Pizzaverse Labs may make changes to this Agreement from time to time.
          When Pizzaverse Labs makes such changes, we will make the updated
          Agreement available on this website and update the “Last Updated” date
          at the beginning of the Agreement accordingly. Please check this page
          periodically for changes. Any changes to this Agreement will apply on
          the date that they are made and, by way of example, Your continued
          access to or use of the Pizzaverse NFT and the Art after the Agreement
          has been updated will constitute your binding acceptance of the
          updates.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          6. Eligibility
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          (a) Participation in the Pizzaverses NFT Collection Offering is open
          only to individuals who have the right and authority to enter into
          this Agreement, are fully able and competent to satisfy the terms,
          conditions, and obligations herein and who are using currency that
          such party is the lawful holder thereof. (b) By becoming a User, you
          represent and warrant that you are at least 18 years old.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          7. Indemnity
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          You will defend, indemnify, and hold Pizzaverse Labs, including each
          of their respective affiliates, subsidiaries, parents, successors and
          assigns, and each of our respective officers, directors, employees,
          agents, or shareholders, harmless from any claims, actions, suits,
          losses, costs, liabilities and expenses (including reasonable
          attorneys’ fees) relating to or arising out of your purchase, sale or
          possession of the Pizzaverse NFT and/or Your participation in the
          Offering, including: (1) Your breach of this Agreement or the
          documents it incorporates by reference; (2) Your violation of any law
          or the rights of a third party as a result of your own interaction
          with such third party; (3) any allegation that any materials that You
          submit to us or transmit in the course of the auction, communications
          seeking Pizzaverse Labs consent to activities or otherwise, infringe
          or otherwise violate the copyright, trademark, trade secret or other
          intellectual property or other rights of any third party; and/or (4)
          any other activities in connection with the Offering or the Pizzaverse
          NFT. This indemnity shall be applicable without regard to the
          negligence of any party, including any indemnified person.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          8. Translation
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          If you are provided a translation of this Agreement, the original
          version in English will be used in deciding any issues or disputes
          which arise under this Agreement.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          9. Severability
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          If any term or provision of this Agreement is invalid, illegal, or
          unenforceable in any jurisdiction, such invalidity, illegality, or
          unenforceability shall not affect any other term or provision of this
          Agreement or invalidate or render unenforceable such term or provision
          in any other jurisdiction.
        </p>
      </div>
      <div>
        <p className="py-4 text-xl font-normal font-varela text-text">
          10. Contact us
        </p>
      </div>
      <div>
        <p className="font-normal text-l font-varela text-text">
          If you have any questions or concerns, including if you need to access
          this Agreement in an alternative format, we encourage you to contact
          us via the provided discord.
        </p>
      </div>
    </div>
  );
};

export default Terms;
